<template>
  <div>
    <table-local-v-1-widget
      :items="items"
      :columns="columns"
      :actions="actions"
      :loading="loading"
      @clickView="row => $emit('clickView', row)"
      @clickConfirm="row => $emit('clickApprove', row)"
      @clickReject="row => $emit('clickReject', row)"
    />
    <!-- <pre>{{ items }}</pre> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      columns: [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: 'created_at',
          formatFn: val => this.$date(val).format('DD-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ร้าน',
          field: 'user_as_shop_name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดคงเหลือเดิม',
          field: 'transaction_data_as_balance',
          type: 'currency',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ยอดถอน',
          field: 'transaction_data_as_amount',
          type: 'currency',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        // {
        //   label: 'หักณที่จ่าย',
        //   field: 'withholding',
        //   formatFn: value => {
        //     const n = Math.round(Number(value))
        //     if (n === 0) return 'ฟรี'

        //     return `${n}%`
        //   },
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        //   sortable: false,
        // },
        // {
        //   label: 'ค่าธรรมเนียมถอน',
        //   field: 'withdrawal_fee',
        //   type: 'currency',
        //   tdClass: 'text-center text-danger',
        //   thClass: 'text-center',
        // },
        {
          label: 'ยอดสุทธิ',
          field: 'transaction_data_as_withdraw_amount',
          type: 'currency',
          tdClass: 'text-center text-success',
          thClass: 'text-center',
        },
      ],
      actions: [
        { keyEmit: 'clickView', iconName: 'EyeIcon', label: 'ดูเพิ่มเติม' },
        { keyEmit: 'clickConfirm', iconName: 'CheckCircleIcon', classIcon: 'text-success', label: 'อนุมัติ' },
        { keyEmit: 'clickReject', iconName: 'XCircleIcon', classIcon: 'text-danger', label: 'ไม่อนุมัติ' },
      ],
      loading: false,
    }
  },
  created() {
    this.getWithdrawPending()
  },
  methods: {
    async getWithdrawPending() {
      this.loading = true
      const resp = await this.api.get('api/admin/partner-withdraw/index?filter=pending').catch(() => {})
      // console.log('getWithdrawPending', resp)
      if (resp) {
        this.items = [...resp]
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
